import './marquee.scss';
import { on_ready } from 'js/utils';
import Marquee from 'classes/Marquee';

on_ready(()=>{
    document.querySelectorAll('.ff_marquee').forEach(container=>{
        const settings = JSON.parse(container.dataset.settings);
        const speed = parseInt(settings.speed);
        new Marquee(container.querySelector('.items'), { duration: speed });
    })
})